<template>
  <iframe src="https://www.chipsresale.com/admin/stock/list/no.examine"></iframe>
</template>

<script>
export default {
  name: "stock.list.no.examine",
  emits : [ 'setMenuKey' ],
  setup(props, { emit }){
    emit('setMenuKey', 'dj.scsp');
  }
}
</script>

<style scoped>

</style>